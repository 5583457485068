import toastr from 'toastr';
import { setCookie, getCookie } from './cookies';

export function initFavoriteButton() {
  const favoriteButtons = document.querySelectorAll('.js-favorite-button');
  if (!favoriteButtons) return;

  favoriteButtons.forEach((favoriteButton) => {
    favoriteButton.addEventListener('click', () => {
      toggleFavorite(favoriteButton);
    });
  });
}

toastr.options = {
  timeOut: 1000,
};

function toggleFavorite(favoriteButton) {
  const jobId = Number(favoriteButton.dataset.jobId) || null;
  if (!jobId) return;

  if (favoriteButton.dataset.guestFavorite) {
    // 非会員の場合
    const guestFavorite = favoriteButton.dataset.guestFavorite === 'true';

    if (guestFavorite) {
      removeGuestFavoriteJob(favoriteButton, jobId);
    } else {
      addGuestFavoriteJob(favoriteButton, jobId);
    }
  } else if (favoriteButton.dataset.isFavorite) {
    // 会員の場合
    const isFavorite = favoriteButton.dataset.isFavorite === 'true';
    const header = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    };

    if (isFavorite) {
      removeMemberFavorite(favoriteButton, jobId, header);
    } else {
      addMemberFavorite(favoriteButton, jobId, header);
    }
  }
}

function getFavoriteJobIds() {
  return getCookie('favorite_job_ids') || [];
}

function addGuestFavoriteJob(favoriteButton, jobId) {
  if (!jobId) return;

  const favoriteJobIds = getFavoriteJobIds();
  const newFavoriteJobIds = [...favoriteJobIds, jobId];
  setCookie('favorite_job_ids', JSON.stringify(newFavoriteJobIds));
  favoriteButton.dataset.guestFavorite = 'true';
  toastr.info('お気に入りに追加しました');
}

function removeGuestFavoriteJob(favoriteButton, jobId) {
  if (!jobId) return;

  const favoriteJobIds = getFavoriteJobIds();
  const newFavoriteJobIds = favoriteJobIds.filter((id) => id !== jobId);
  setCookie('favorite_job_ids', JSON.stringify(newFavoriteJobIds));
  favoriteButton.dataset.guestFavorite = 'false';
  toastr.info('お気に入りから削除しました');
}

async function addMemberFavorite(favoriteButton, jobId, header) {
  if (!jobId) return;

  const postUrl = `/mypage/favorites?job_id=${jobId}`;
  const response = await fetch(postUrl, { method: 'POST', headers: header });

  if (response.ok) {
    favoriteButton.dataset.isFavorite = 'true';
    toastr.info('お気に入りに追加しました');
  } else {
    console.error('error', response);
  }
}

async function removeMemberFavorite(favoriteButton, jobId, header) {
  if (!jobId) return;

  const deleteUrl = `/mypage/favorites/${jobId}`;
  const response = await fetch(deleteUrl, {
    method: 'DELETE',
    headers: header,
  });

  if (response.ok) {
    favoriteButton.dataset.isFavorite = 'false';
    toastr.info('お気に入りから削除しました');
  } else {
    console.error('error', response);
  }
}

function getCsrfToken() {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
}
